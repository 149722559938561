<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form ref="resetForm">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :rules="[validators.required]"
                label="Current Password"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :rules="[validators.required, validators.minLengthValidator(newPassword, 8)]"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :rules="[validators.required, validators.confirmedValidator(cPassword,newPassword)]"
                label="Confirm New Password"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <v-card-text v-if="errorMessage">
          <v-alert text
                   color="error">
            <small class="d-block mb-1">
              {{ errorMessage }}
            </small>
          </v-alert>
        </v-card-text>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!--<v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Two factor authentication is not enabled yet.
          </p>
          <p class="text-sm text--primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in. Learn more.
          </p>
        </v-card-text>-->

        <!-- action buttons -->
        <v-card-text>
          <v-btn :loading="loading" color="primary" class="me-3 mt-3" @click="SetNewPassword">
            Set new password
          </v-btn>

        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')
    const resetForm = ref(null)
    const errorMessage = ref(null)
    const loading = ref(false);

    const { route, router } = useRouter();

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    const SetNewPassword = () => {

      if (resetForm.value.validate()) {
        errorMessage.value = null;
        loading.value = true;
        vm.$http
          .post(vm.$store.state.apiUrl + '/auth/change-password/', {
            CurrentPassword: currentPassword.value,
            Password: newPassword.value
          })
          .then(response => {
            loading.value = false;

            if (response.data) {

              store.commit('app/SNACKBAR_ADD', { color: "success", message: "Password Updated." });
              router.push({ name: 'dashboard' });

            } else {
              errorMessage.value = "Your current password was incorrect."
            }
          })
          .catch(error => {
            loading.value = false;
            errorMessage.value = "Something has gone wrong, please try again later."
          });
      }
    }

    return {
      loading,
      resetForm,
      errorMessage,
      SetNewPassword,
      validators,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
